import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

// Connects to data-controller="date-picker"
export default class extends Controller {
  static targets = ["datePicker"];

  static values = {
    initialDate: String,
    maxDate: String,
    minDate: String,
  };

  connect() {
    this.picker = flatpickr(this.datePickerTarget, {
      altInput: true,
      altFormat: "F j, Y",
      enableTime: false,
      maxDate: this.maxDateValue || 'today',
      minDate: this.minDateValue || new Date().fp_incr(-365),
      defaultDate: new Date(this.initialDateValue) || null,
      dateFormat: "Y-m-d",
    });
  }

  disconnect() {
    this.picker.destroy();
  }
}