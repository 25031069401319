import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="date-range"
export default class extends Controller {
  static targets = ['startDate', 'endDate', 'searchBtn', 'searchIconBtn'];

  updateDate(event) {
    const inputDate = new Date(event.target.value);
    inputDate.setHours(0, 0, 0, 0);
    
    var todaysDate = new Date();
    if (this.endDateTarget.dataset.isPnlReport == 'true' ){
      todaysDate = new Date(todaysDate.getFullYear, todaysDate.getMonth + 1, 0)
    }
    todaysDate.setHours(0, 0, 0, 0);

    const startDate = new Date(this.startDateTarget.value);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(this.endDateTarget.value);
    endDate.setHours(0, 0, 0, 0);
    
    const eventType = event.target.dataset.dateRangeTarget;
    const isEventField = this.startDateTarget.dataset.isEventField === 'true' ? true : false;
    this.validateFutureDate(eventType, inputDate, todaysDate, startDate, endDate, isEventField);
  }

  validateFutureDate(eventType, inputDate, todaysDate, startDate, endDate, isEventField) {
    const targetElement =
      eventType === 'startDate' ? this.startDateTarget : this.endDateTarget;
    const isFutureDate = inputDate > todaysDate;

    if (isFutureDate && !isEventField) {
      this.disableButton();
      targetElement.classList.add('border-red-500');
    } else {
      if(eventType === 'startDate'){
        this.setMinDateOnEndDateField(inputDate)
      } else {
        this.setMaxDateOnStartDateField(inputDate)
      }
      this.validateDateRange(todaysDate, startDate, endDate, isEventField);
    }
  }

  validateDateRange(todaysDate, startDate, endDate, isEventField) {
    if (isEventField ? startDate > endDate : (startDate > endDate || endDate > todaysDate)) {
      this.endDateTarget.classList.add('border-red-500');
      this.disableButton();
    } else {
      this.enableButton();
      this.startDateTarget.classList.remove('border-red-500');
      this.endDateTarget.classList.remove('border-red-500');
    }
  }

  updateReportEndDate () {
    let startDate = new Date(this.startDateTarget.value)
    let endDate = new Date(this.endDateTarget.value)
    const todaysDate = new Date();

    if (!isNaN(startDate) && !isNaN(endDate)) {
      let diffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24)
      let newEndDate = new Date(startDate)
      newEndDate.setDate(startDate.getDate() + 365)
      
      if (diffInDays > 365) {
        
        this.endDateTarget.value = newEndDate.toISOString().split('T')[0]
        this.endDateTarget.min = startDate.toISOString().split('T')[0]
        this.endDateTarget.max = newEndDate.toISOString().split('T')[0]
        this.startDateTarget.max = newEndDate.toISOString().split('T')[0]
        
      } else {
        this.endDateTarget.min = startDate.toISOString().split('T')[0]
        const maxDate = (newEndDate <= todaysDate) ? newEndDate : todaysDate
        this.endDateTarget.max = maxDate.toISOString().split('T')[0]
      }
    }
  }
  enableButton() {
    this.searchBtnTarget.removeAttribute('disabled');
    this.searchIconBtnTarget.removeAttribute('disabled');
  }

  disableButton() {
    this.searchBtnTarget.setAttribute('disabled', 'disabled');
    this.searchIconBtnTarget.setAttribute('disabled', 'disabled');
  }
  setMinDateOnEndDateField(inputDate) {
    inputDate.setDate(inputDate.getDate() + 1)
    this.endDateTarget.setAttribute(
      'min',
      inputDate.toISOString().split('T')[0]
    )
  }
  setMaxDateOnStartDateField(inputDate) {
    inputDate.setDate(inputDate.getDate() + 1)
    this.startDateTarget.setAttribute(
      'max',
      inputDate.toISOString().split('T')[0]
    )
  }
}
