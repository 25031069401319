import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
require("flatpickr/dist/plugins/monthSelect/style.css")


// Connects to data-controller="month-picker"
export default class extends Controller {
  static targets = ["monthPicker"]

  static values = {
    initialMonth: String,
    maxDate: String,
    minDate: String
  }

  connect() {
    this.picker = flatpickr(this.monthPickerTarget, {
      enableTime: true,
      maxDate: this.maxDateValue || 'today',
      minDate: this.minDateValue || new Date().fp_incr(-365),
      defaultDate: this.initialMonthValue || null,
      plugins: [new monthSelectPlugin({
        shorthand: true, //defaults to false
        dateFormat: "Y-m", //defaults to "F Y"
        altFormat: "Y-m", //defaults to "F Y"
      })],
      disableMobile: true
    });
  }

  disconnect() {
    this.picker.destroy();
  }
}
