import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="profit-and-loss"
export default class extends Controller {
  static targets = [ 
    "filterToggle", 
    "dateRangeContainer", 
    "monthContainer", 
    "dateRangeInput", 
    "monthInput" 
  ]
  connect() {
    
  }

  toggleFilter() {
    if (this.filterToggleTarget.checked) {
      this.showDateRangeFilter()
    } else {
      this.showMonthFilter()
    }
  }

  showDateRangeFilter() {
    // Hide month container
    this.monthContainerTarget.classList.add('hidden')
    this.monthInputTarget.disabled = true

    // Show date range container
    this.dateRangeContainerTarget.classList.remove('hidden')
    this.dateRangeInputs.forEach(input => input.disabled = false)
  }

  showMonthFilter() {
    // Hide date range container
    this.dateRangeContainerTarget.classList.add('hidden')
    this.dateRangeInputs.forEach(input => input.disabled = true)

    // Show month container
    this.monthContainerTarget.classList.remove('hidden')
    this.monthInputTarget.disabled = false
  }

  get dateRangeInputs() {
    return this.dateRangeInputTargets
  }
}



