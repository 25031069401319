import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

// Connects to data-controller="date-time-picker"
export default class extends Controller {
  static targets = ["dateTimePicker"];

  static values = {
    initialDate: String,
    maxDate: String,
    minDate: String,
  };

  connect() {
    this.picker = flatpickr(this.dateTimePickerTarget, {
      enableTime: true,
      maxDate: this.maxDateValue || new Date().fp_incr(365),
      minDate: this.minDateValue || new Date().fp_incr(-365),
      defaultDate: this.initialDateValue || null,
      dateFormat: "Y-m-d H:i"
    });
  }

  disconnect() {
    this.picker.destroy();
  }
}