import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer-filter-confirm-box"
export default class extends Controller {
  static targets = ["resetButton", "filtersPaymentPreference", "formPaymentPreference", "filtersCustomerFilter", "filtersForm"]
  connect() {
  }

  cancel(){
    if(this.hasResetButtonTarget){
      this.resetButtonTarget.click();
    }
  }

  apply(){
   if(this.hasFormPaymentPreferenceTarget){
    this.filtersPaymentPreferenceTarget.innerHTML = '';
    const formPaymentPreferences = this.formPaymentPreferenceTarget;
    if(this.hasFiltersPaymentPreferenceTarget){
      this.filtersPaymentPreferenceTarget.appendChild(formPaymentPreferences.cloneNode(true));
    }
   }
  }

  proceed(event){
    if(this.hasFiltersCustomerFilterTarget){
      this.filtersFormTarget.innerHTML = '';
      const filtersCustomerFilter = this.filtersCustomerFilterTarget;
      if(this.hasFiltersFormTarget){
        this.filtersFormTarget.appendChild(filtersCustomerFilter.cloneNode(true));
        event.currentTarget.closest("#confirm_box").remove(); //  for closing confirm box model
        filtersCustomerFilter.classList.add("hidden")
      }
     }
  }

  selectUpdate(event){
    event.currentTarget.options[event.currentTarget.selectedIndex].setAttribute("selected","");
  }
}
