import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";


// Connects to data-controller="month-picker"
export default class extends Controller {
  static targets = ["firstDateInput", "secondDateInput"]

  static values = {
    initialDate: String,
    endDate: String,
    maxDate: String,
    minDate: String
  }

  connect() {
    this.picker = flatpickr(this.firstDateInputTarget, {
      enableTime: false,
      maxDate: this.maxDateValue || 'today',
      minDate: this.minDateValue || new Date(2020,0.1),
      defaultDate: new Date(this.initialDateValue) || null,
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: this.secondDateInputTarget})],
      disableMobile: true,
      onReady: () => {
        // ✅ Set second date manually if provided
        if (this.endDateValue) {
          this.secondDateInputTarget.value = this.endDateValue;
        }
      }
    });
  }


  disconnect() {
    this.picker.destroy();
  }
}
